import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function Blog() {
return (
    <>
     <div className="bradcrumb light-bg">
				<div className="container">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb mb-0">
							<li className="breadcrumb-item"><a href="/">Home</a></li>
							<li className="breadcrumb-item active" aria-current="page">Blog</li>
						</ol>
					</nav>
				</div>
	</div>
    <div className="blog-section">
        <div className="container">
            <div className="heading-block mb-5 mt-5">
                <h1 className="font-44 color-black text-uppercase text-center">Blog</h1>
            </div>
            <div className="blog-ingrid">
                <div className="row">
                    <div className="col-md-4">
                            <a href="/blog-detail" className="blo-bt">
                                <div className="blog-card">
                                    <div className="blog-img">
                                    <img src="img/blog/bog-1.jpg" className="img-fluid" />
                                    </div>
                                    <div className="blog-content">
                                        <p className="blog-meta">22 April, 2024 | <spn className="color-primary">Real Estate</spn>
                                        </p>
                                        <h3>Lorem Ipsum is simply dummy text..</h3>
                                    </div>
                                </div>
                            </a>
                    </div>
                    <div className="col-md-4">
                            <a href="/blog-detail" className="blo-bt">
                                <div className="blog-card">
                                    <div className="blog-img">
                                    <img src="img/blog/bog-2.jpg" className="img-fluid" />
                                    </div>
                                    <div className="blog-content">
                                        <p className="blog-meta">22 April, 2024 | <spn className="color-primary">Real Estate</spn>
                                        </p>
                                        <h3>Lorem Ipsum is simply dummy text..</h3>
                                    </div>
                                </div>
                            </a>
                    </div>
                    <div className="col-md-4">
                            <a href="/blogdetail" className="blo-bt">
                                <div className="blog-card">
                                    <div className="blog-img">
                                    <img src="img/blog/bog-3.jpg" className="img-fluid" />
                                    </div>
                                    <div className="blog-content">
                                        <p className="blog-meta">22 April, 2024 | <spn className="color-primary">Real Estate</spn>
                                        </p>
                                        <h3>Lorem Ipsum is simply dummy text..</h3>
                                    </div>
                                </div>
                            </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    );
}

export default Blog;
