import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function BlogDetail(){
    return (
        <>
         <div className="bradcrumb light-bg">
				<div className="container">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb mb-0">
							<li className="breadcrumb-item"><a href="/">Home</a></li>
							<li className="breadcrumb-item active" aria-current="page">Blog Details</li>
						</ol>
					</nav>
				</div>
	    </div>
            <div className="container">
                <div className="section-title text-start">
                    <h2 className="font-30 color-black font-bold">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h2>
                    <p className="font-14 color-black mb-0">
                        Posted in: 14 April 2024
                    </p>
                </div>
                <div className="blog-ingrid">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="blog-description">
                                <div className="blog-img">
                                    <img src="img/blog/bog-1.jpg" className="img-fluid" />

                                </div>
                                <div className="content-srd text-start">
                                    <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 
                                    1500s, when an unknown printer took a galley of type and scrambled it to
                                     make a type specimen book. It has survived not only five centuries, but 
                                     also the leap into electronic typesetting, remaining essentially 
                                     unchanged. It was popularised in the 1960s with the release of Letraset 
                                     sheets containing Lorem Ipsum passages, and more recently with desktop
                                      publishing software like Aldus PageMaker including versions of Lorem 
                                      Ipsum.
                                    </p>
                                    <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 
                                    1500s, when an unknown printer took a galley of type and scrambled it to
                                     make a type specimen book. It has survived not only five centuries, but 
                                     also the leap into electronic typesetting, remaining essentially 
                                     unchanged. It was popularised in the 1960s with the release of Letraset 
                                     sheets containing Lorem Ipsum passages, and more recently with desktop
                                      publishing software like Aldus PageMaker including versions of Lorem 
                                      Ipsum.
                                    </p>

                                    <h4>Lorem Ipsum is simply dummy</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="recent-blg mob-hide text-start">
                            <h4 className="text-uppercase mb-2 font-bold">RECENT POST</h4>
                                <a href="#">
                                    <div className="row-blog">
                                        <div className="blog-img">
                                            <img src="img/blog/bog-2.jpg" className="img-fluid" />
                                        </div>
                                        <div className="blog-content">
                                            <h3>Lorem Ipsum is simply dummy </h3>
                                            <p className="blog-meta">22 April 2024 </p>
                                        </div>
                                    </div>
                                </a>
                                <a href="#">
                                    <div className="row-blog">
                                        <div className="blog-img">
                                            <img src="img/blog/bog-3.jpg" className="img-fluid" />
                                        </div>
                                        <div className="blog-content">
                                            <h3>Lorem Ipsum is simply dummy  </h3>
                                            <p className="blog-meta">8 April 2024 </p>
                                        </div>
                                    </div>
                                </a>
                        </div>

                    </div>
                    </div>
                </div>

            </div>
       

    </>
    );
}
export default BlogDetail;