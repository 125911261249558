import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function Career(){
    return (
        <>
            <div className="bradcrumb light-bg">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Career</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="section career">
                <div className="container ">
                      <div className="width-sm mt-5">
                        <div className="career-single">
                             <h4>Job Heading</h4>
                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                when an unknown printer took a galley of type and scrambled it to make a type 
                                specimen book. It has survived not only five centuries, but also the leap into 
                                electronic typesetting, remaining essentially unchanged. It was popularised in 
                                the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                and more recently with desktop publishing software like Aldus PageMaker 
                                including versions of Lorem Ipsum.</p>
                        </div>
                        <div className="career-single">
                             <h4>Job Heading</h4>
                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                when an unknown printer took a galley of type and scrambled it to make a type 
                                specimen book. It has survived not only five centuries, but also the leap into 
                                electronic typesetting, remaining essentially unchanged. It was popularised in 
                                the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                and more recently with desktop publishing software like Aldus PageMaker 
                                including versions of Lorem Ipsum.</p>
                        </div>
                        <div className="career-single">
                             <h4>Job Heading</h4>
                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                when an unknown printer took a galley of type and scrambled it to make a type 
                                specimen book. It has survived not only five centuries, but also the leap into 
                                electronic typesetting, remaining essentially unchanged. It was popularised in 
                                the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                and more recently with desktop publishing software like Aldus PageMaker 
                                including versions of Lorem Ipsum.</p>
                        </div>
                        <div className="career-single">
                             <h4>Job Heading</h4>
                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                when an unknown printer took a galley of type and scrambled it to make a type 
                                specimen book. It has survived not only five centuries, but also the leap into 
                                electronic typesetting, remaining essentially unchanged. It was popularised in 
                                the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                and more recently with desktop publishing software like Aldus PageMaker 
                                including versions of Lorem Ipsum.</p>
                        </div>
                        <div className="cta-form career-form">
                                <div className="section-title text-center p-0">
                                    <h2 className="color-primary font-30 mb-3">JOBS INFORMATION</h2>
                                </div>
                                <div className="contact-form ">
                                    <form id="base_form" method="post" data-gtm-form-interact-id="0">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="base_name" className="form-label">First Name</label>
                                                        <input type="text" name="base_name" id="base_name" placeholder="Enter here" className="form-control" value=""/>
                                                            
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="last_name" className="form-label">Last Name</label>
                                                        <input type="text" name="last_name" id="last_name" placeholder="Enter here" className="form-control" value=""/>
                                                            
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="base_email" className="form-label">Email</label>
                                                        <input type="email" name="base_email" id="base_email" placeholder="Enter here" className="form-control" value=""/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="base_education" className="form-label">Education Background</label>
                                                            <select className="form-select" name="base_education" id="base_education">
                                                                <option selected>Select</option>
                                                                <option value="1">Master Degree</option>
                                                                <option value="2">Graduate</option>
                                                                <option value="3">Under Gaduate</option>
                                                                <option value="4">Others</option>
                                                            </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="base_jobs" className="form-label">Choose Jobs</label>
                                                            <select className="form-select" name="base_jobs" id="base_jobs">
                                                                <option selected>Select</option>
                                                                <option value="1">Render Artist</option>
                                                                <option value="2">Animation Render Artist</option>
                                                                <option value="3">Video Post-production Artist</option>
                                                                <option value="4">Project Manager</option>
                                                            </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="base_message" className="form-label">Write your message</label>
                                                        <textarea name="base_message" id="base_message" cols="3" rows="3" value="" placeholder="Enter here" className="form-control" data-gtm-form-interact-field-id="0">                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-sm-12 mt-3 mb-4 ">
                                                    <button type="submit" id="base_submit" className="btn btn-primary bt-blue">
                                                        Send Message
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                      </div>
                </div>
            </div>
        </>
  
    );
}
export default Career;